<template>
    <component
        class="ui-icon-button"
        v-sanitize-href="link"
        v-bind="{ ...$attrs, ...currentBindings }"
        :is="tag"
        :disabled="loading || disabled"
        :type="type"
        :class="buttonClasses"
    >
        <slot>
            <span
                v-if="icon && !loading"
                :class="loading ? 'fas fa-spinner fa-spin' : icon"
            ></span>
        </slot>
    </component>
</template>

<script>
import buttonMixin from '@/mixins/button.mixin';

export default {
    name: 'UiIconButton',
    mixins: [buttonMixin],
    computed: {
        buttonClasses() {
            const classes = [];

            if (this.variant) {
                classes.push(`ui-icon-button--${this.variant}`);
            }

            if (this.disabled) {
                classes.push('ui-icon-button--disabled');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-icon-button {
    align-items: center;
    background-color: $general-white;
    border: 1px solid $general-white;
    border-radius: 4px;
    color: $ecart-secondary-600;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    line-height: 1;
    text-decoration: none;    
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    width: 32px;
    
    &:hover {
        border-color: $ecart-secondary-100;
        text-decoration: none;
    }
    
    // Variants
    &--whatsapp {
        background-color: #2ecc71;
        border-color: #2ecc71;
        color: #ffffff;
        font-size: 18px;

        &:hover {
            background-color: rgba(#2ecc71, 0.8);
            border-color: rgba(#2ecc71, 0.8);
        }
    }

    &--phone {
        background-color: #0283cc;
        border-color: #0283cc;
        color: #ffffff;

        &:hover {
            background-color: rgba(#0283cc, 0.8);
            border-color: rgba(#0283cc, 0.8);
        }
    }

    &--email {
        background-color: #ffb136;
        border-color: #ffb136;
        color: #ffffff;

        &:hover {
            background-color: rgba(#ffb136, 0.8);
            border-color: rgba(#ffb136, 0.8);
        }
    }

    &--disabled {
        background-color: $ecart-secondary-100;
        border-color: $ecart-secondary-100;
        color: $ecart-secondary-300;
        pointer-events: none;
    }
}
</style>
