<template>
    <div class="contact-links__contact-buttons">
        <div v-b-tooltip="phone ? null : 'The user does not have a phone.'">
            <ui-icon-button
                variant="whatsapp"
                icon="fab fa-whatsapp"
                target="_blank"
                :link="`https://api.whatsapp.com/send?phone=${phone}`"
                :disabled="!phone"
            />
        </div>
        <div v-b-tooltip="phone ? null : 'The user does not have a phone.'">
            <ui-icon-button
                variant="phone"
                icon="fas fa-phone"
                :link="`tel:+${phone}`"
                :disabled="!phone"
            />
        </div>
        <div v-b-tooltip="email ? null : 'The user does not have an email.'">
            <ui-icon-button
                variant="email"
                icon="fas fa-envelope"
                :link="`mailto:${email}`"
                :disabled="!email"
            />
        </div>
    </div>
</template>

<script>
import UiIconButton from '@/components/ui/IconButton.vue';

export default {
    name: 'ContactLinks',
    components: { UiIconButton },
    props: {
        phone: {
            type: String,
            default: null,
            required: false,
        },
        email: {
            type: String,
            default: null,
            required: false,
        }
    }
};
</script>

<style lang="scss">
.contact-links {
    &__contact-buttons {
        display: flex;
        gap: 5px;
    }
}
</style>
